//converted from hex array originally from here: https://gist.github.com/mucar/3898821
//372 colors, will need more eventually
export const colors = [
  "rgba(105,149,186,1)",
  "rgba(225,69,186,1)",
  "rgba(12,211,109,1)",
  "rgba(215,10,156,1)",
  "rgba(152,107,83,1)",
  "rgba(86,108,160,1)",
  "rgba(242,5,230,1)",
  "rgba(210,152,226,1)",
  "rgba(206,252,184,1)",
  "rgba(8,139,175,1)",
  "rgba(59,140,42,1)",
  "rgba(143,226,42,1)",
  "rgba(82,18,80,1)",
  "rgba(245,4,34,1)",
  "rgba(150,229,145,1)",
  "rgba(122,210,54,1)",
  "rgba(145,30,32,1)",
  "rgba(210,115,125,1)",
  "rgba(162,248,165,1)",
  "rgba(168,184,212,1)",
  "rgba(203,37,130,1)",
  "rgba(208,46,41,1)",
  "rgba(246,151,193,1)",
  "rgba(14,182,33,1)",
  "rgba(115,135,42,1)",
  "rgba(15,89,151,1)",
  "rgba(20,169,173,1)",
  "rgba(65,209,88,1)",
  "rgba(74,84,63,1)",
  "rgba(82,13,58,1)",
  "rgba(197,164,251,1)",
  "rgba(19,80,206,1)",
  "rgba(119,119,42,1)",
  "rgba(190,96,139,1)",
  "rgba(15,82,95,1)",
  "rgba(226,61,208,1)",
  "rgba(100,130,15,1)",
  "rgba(103,156,157,1)",
  "rgba(127,180,17,1)",
  "rgba(47,63,148,1)",
  "rgba(242,81,14,1)",
  "rgba(96,133,114,1)",
  "rgba(148,112,2,1)",
  "rgba(121,128,110,1)",
  "rgba(17,222,193,1)",
  "rgba(121,188,160,1)",
  "rgba(174,144,226,1)",
  "rgba(72,52,208,1)",
  "rgba(229,30,37,1)", // pride colors start here
  "rgba(246,140,30,1)",
  "rgba(249,238,19,1)",
  "rgba(13,128,64,1)",
  "rgba(62,95,172,1)",
  "rgba(115,42,133,1)",
  "rgba(250,252,255,1)",
  "rgba(255,175,200,1)",
  "rgba(116,215,238,1)",
  "rgba(97,57,21,1)",
  "rgba(3,4,5,1)", // pride colors end here - juneteenth
  "rgba(59,140,42,1)",
  "rgba(218,150,125,1)",
  "rgba(121,46,216,1)",
  "rgba(211,72,109,1)",
  "rgba(87,196,216,1)",
  "rgba(71,72,147,1)",
  "rgba(79,15,111,1)",
  "rgba(234,36,163,1)",
  "rgba(33,213,46,1)",
  "rgba(0,247,249,1)",
  "rgba(214,221,146,1)",
  "rgba(208,0,67,1)",
  "rgba(214,221,146,1)",
  "rgba(164,138,158,1)",
  "rgba(138,150,198,1)",
  "rgba(33,83,142,1)",
  "rgba(170,34,109,1)",
  "rgba(63,22,217,1)",
  "rgba(0,35,184,1)",
  "rgba(30,194,39,1)",
  "rgba(141,108,47,1)",
  "rgba(199,155,194,1)",
  "rgba(91,179,45,1)",
  "rgba(147,242,215,1)",
  "rgba(107,46,95,1)",
  "rgba(241,174,22,1)",
  "rgba(52,137,31,1)",
  "rgba(100,130,15,1)",
  "rgba(252,126,65,1)",
  "rgba(136,233,184,1)",
  "rgba(27,237,230,1)",
  "rgba(203,91,234,1)",
  "rgba(135,152,164,1)",
  "rgba(26,128,106,1)",
  "rgba(137,213,52,1)",
  "rgba(193,136,162,1)",
  "rgba(248,18,179,1)",
  "rgba(33,83,142,1)",
  "rgba(45,255,246,1)",
  "rgba(239,110,60,1)",
  "rgba(0,239,212,1)",
  "rgba(162,89,164,1)",
  "rgba(32,246,186,1)",
  "rgba(13,90,193,1)",
  "rgba(47,17,121,1)",
  "rgba(152,63,122,1)",
  "rgba(152,107,83,1)",
  "rgba(222,115,194,1)",
  "rgba(178,194,79,1)",
  "rgba(177,21,115,1)",
  "rgba(81,61,152,1)",
  "rgba(126,80,168,1)",
  "rgba(155,92,42,1)",
  "rgba(220,28,6,1)",
  "rgba(76,240,157,1)",
  "rgba(168,43,137,1)",
  "rgba(189,224,82,1)",
  "rgba(48,204,73,1)",
  "rgba(101,27,230,1)",
  "rgba(251,33,163,1)",
  "rgba(227,62,82,1)",
  "rgba(196,253,87,1)",
  "rgba(82,18,80,1)",
  "rgba(103,73,232,1)",
  "rgba(206,125,120,1)",
  "rgba(198,196,44,1)",
  "rgba(223,81,74,1)",
  "rgba(202,71,81,1)",
  "rgba(28,3,101,1)",
  "rgba(196,214,71,1)",
  "rgba(34,137,22,1)",
  "rgba(211,102,71,1)",
  "rgba(224,140,86,1)",
  "rgba(5,211,113,1)",
  "rgba(164,209,122,1)",
  "rgba(168,74,143,1)",
  "rgba(118,252,27,1)",
  "rgba(145,105,136,1)",
  "rgba(245,59,42,1)",
  "rgba(178,190,87,1)",
  "rgba(234,158,112,1)",
  "rgba(255,219,225,1)",
  "rgba(40,152,18,1)",
  "rgba(240,120,21,1)",
  "rgba(9,113,240,1)",
  "rgba(61,103,81,1)",
  "rgba(220,231,122,1)",
  "rgba(72,180,27,1)",
  "rgba(195,200,157,1)",
  "rgba(99,95,109,1)",
  "rgba(37,6,98,1)",
  "rgba(211,73,58,1)",
  "rgba(219,162,230,1)",
  "rgba(153,108,72,1)",
  "rgba(26,128,107,1)",
  "rgba(136,170,11,1)",
  "rgba(103,235,75,1)",
  "rgba(29,234,167,1)",
  "rgba(201,169,65,1)",
  "rgba(6,14,39,1)",
  "rgba(252,69,142,1)",
  "rgba(76,162,249,1)",
  "rgba(205,47,0,1)",
  "rgba(198,225,232,1)",
  "rgba(121,53,44,1)",
  "rgba(255,244,215,1)",
  "rgba(107,46,95,1)",
  "rgba(64,145,136,1)",
  "rgba(54,72,106,1)",
  "rgba(100,129,119,1)",
  "rgba(234,36,163,1)",
  "rgba(175,49,1,1)",
  "rgba(201,215,48,1)",
  "rgba(164,228,63,1)",
  "rgba(21,185,238,1)",
  "rgba(180,192,134,1)",
  "rgba(238,145,227,1)",
  "rgba(64,109,249,1)",
  "rgba(178,180,240,1)",
  "rgba(250,6,236,1)",
  "rgba(199,158,210,1)",
  "rgba(26,128,17,1)",
  "rgba(158,109,113,1)",
  "rgba(245,4,34,1)",
  "rgba(7,215,246,1)",
  "rgba(186,150,206,1)",
  "rgba(0,248,124,1)",
  "rgba(26,230,219,1)",
  "rgba(137,213,52,1)",
  "rgba(152,63,122,1)",
  "rgba(150,133,235,1)",
  "rgba(117,216,158,1)",
  "rgba(180,98,56,1)",
  "rgba(227,164,129,1)",
  "rgba(27,182,153,1)",
  "rgba(176,216,123,1)",
  "rgba(156,182,74,1)",
  "rgba(92,221,135,1)",
  "rgba(91,228,240,1)",
  "rgba(63,132,115,1)",
  "rgba(97,218,94,1)",
  "rgba(143,180,19,1)",
  "rgba(28,101,203,1)",
  "rgba(66,31,121,1)",
  "rgba(179,8,211,1)",
  "rgba(241,88,191,1)",
  "rgba(125,29,133,1)",
  "rgba(62,70,76,1)",
  "rgba(228,172,68,1)",
  "rgba(252,107,87,1)",
  "rgba(211,102,71,1)",
  "rgba(44,161,174,1)",
  "rgba(119,236,202,1)",
  "rgba(40,252,253,1)",
  "rgba(83,147,151,1)",
  "rgba(250,6,236,1)",
  "rgba(96,143,164,1)",
  "rgba(1,172,83,1)",
  "rgba(221,147,253,1)",
  "rgba(127,180,17,1)",
  "rgba(81,174,217,1)",
  "rgba(150,176,12,1)",
  "rgba(215,121,15,1)",
  "rgba(251,76,3,1)",
  "rgba(93,29,12,1)",
  "rgba(100,15,193,1)",
  "rgba(199,158,210,1)",
  "rgba(60,236,53,1)",
  "rgba(206,0,190,1)",
  "rgba(147,72,175,1)",
  "rgba(98,192,62,1)",
  "rgba(194,176,226,1)",
  "rgba(147,91,109,1)",
  "rgba(93,44,82,1)",
  "rgba(143,216,131,1)",
  "rgba(225,207,59,1)",
  "rgba(67,106,158,1)",
  "rgba(113,177,244,1)",
  "rgba(231,219,206,1)",
  "rgba(174,173,58,1)",
  "rgba(165,179,217,1)",
  "rgba(211,39,122,1)",
  "rgba(130,60,89,1)",
  "rgba(136,9,119,1)",
  "rgba(42,52,52,1)",
  "rgba(84,38,224,1)",
  "rgba(6,244,58,1)",
  "rgba(29,29,88,1)",
  "rgba(178,219,21,1)",
  "rgba(6,224,82,1)",
  "rgba(75,180,115,1)",
  "rgba(153,102,53,1)",
  "rgba(224,238,184,1)",
  "rgba(97,25,208,1)",
  "rgba(47,123,153,1)",
  "rgba(192,164,60,1)",
  "rgba(75,91,220,1)",
  "rgba(122,61,147,1)",
  "rgba(227,62,82,1)",
  "rgba(121,53,44,1)",
  "rgba(45,125,42,1)",
  "rgba(36,62,235,1)",
  "rgba(177,127,201,1)",
  "rgba(145,30,126,1)",
  "rgba(27,182,153,1)",
  "rgba(178,190,87,1)",
  "rgba(114,96,216,1)",
  "rgba(172,62,27,1)",
  "rgba(134,233,143,1)",
  "rgba(154,185,183,1)",
  "rgba(14,192,255,1)",
  "rgba(255,52,32,1)",
  "rgba(0,35,184,1)",
  "rgba(50,213,214,1)",
  "rgba(128,34,52,1)",
  "rgba(67,106,159,1)",
  "rgba(227,217,76,1)",
  "rgba(99,181,152,1)",
  "rgba(16,229,177,1)",
  "rgba(172,124,10,1)",
  "rgba(180,113,98,1)",
  "rgba(97,90,240,1)",
  "rgba(105,229,89,1)", //added new colors here from excel rand
  "rgba(156,123,245,1)",
  "rgba(78,200,38,1)",
  "rgba(82,195,164,1)",
  "rgba(116,161,21,1)",
  "rgba(122,221,151,1)",
  "rgba(17,150,33,1)",
  "rgba(115,94,218,1)",
  "rgba(110,112,83,1)",
  "rgba(176,57,87,1)",
  "rgba(90,24,135,1)",
  "rgba(204,171,140,1)",
  "rgba(168,55,178,1)",
  "rgba(84,216,157,1)",
  "rgba(143,87,206,1)",
  "rgba(186,25,109,1)",
  "rgba(103,142,40,1)",
  "rgba(247,105,178,1)",
  "rgba(29,23,124,1)",
  "rgba(164,153,27,1)",
  "rgba(152,56,60,1)",
  "rgba(38,73,179,1)",
  "rgba(225,207,230,1)",
  "rgba(56,133,103,1)",
  "rgba(171,26,86,1)",
  "rgba(121,215,113,1)",
  "rgba(128,33,50,1)",
  "rgba(25,157,151,1)",
  "rgba(243,134,253,1)",
  "rgba(127,81,4,1)",
  "rgba(93,143,118,1)",
  "rgba(162,89,81,1)",
  "rgba(88,30,162,1)",
  "rgba(212,117,61,1)",
  "rgba(39,110,75,1)",
  "rgba(62,181,15,1)",
  "rgba(111,86,183,1)",
  "rgba(236,70,247,1)",
  "rgba(7,112,209,1)",
  "rgba(178,54,165,1)",
  "rgba(221,177,207,1)",
  "rgba(59,249,137,1)",
  "rgba(102,43,159,1)",
  "rgba(225,164,175,1)",
  "rgba(226,52,15,1)",
  "rgba(177,253,241,1)",
  "rgba(149,42,112,1)",
  "rgba(168,17,204,1)",
  "rgba(207,203,211,1)",
  "rgba(69,111,113,1)",
  "rgba(170,243,147,1)",
  "rgba(78,218,52,1)",
  "rgba(221,145,35,1)",
  "rgba(58,69,226,1)",
  "rgba(132,134,111,1)",
  "rgba(240,127,167,1)",
  "rgba(3,218,51,1)",
  "rgba(22,21,97,1)",
  "rgba(100,35,53,1)",
  "rgba(27,11,108,1)",
  "rgba(91,238,151,1)",
  "rgba(150,48,228,1)",
  "rgba(78,103,46,1)",
  "rgba(133,90,61,1)",
  "rgba(50,76,227,1)",
  "rgba(226,20,19,1)",
  "rgba(153,66,202,1)",
  "rgba(201,120,67,1)",
  "rgba(23,79,249,1)",
  "rgba(163,103,104,1)",
  "rgba(204,115,32,1)",
  "rgba(65,60,17,1)",
  "rgba(84,31,200,1)",
  "rgba(137,76,126,1)",
  "rgba(171,57,37,1)",
  "rgba(166,229,95,1)",
  "rgba(222,193,8,1)",
  "rgba(82,84,85,1)",
  "rgba(142,12,111,1)",
  "rgba(157,240,21,1)",
  "rgba(195,43,132,1)",
  "rgba(150,40,189,1)",
  "rgba(132,119,15,1)",
  "rgba(194,240,114,1)",
  "rgba(29,80,18,1)",
  "rgba(69,69,75,1)",
  "rgba(165,192,102,1)",
  "rgba(80,83,244,1)",
  "rgba(60,252,141,1)",
  "rgba(182,122,106,1)",
  "rgba(4,213,63,1)",
  "rgba(170,17,149,1)",
  "rgba(67,11,96,1)",
  "rgba(246,206,233,1)",
  "rgba(141,164,216,1)",
  "rgba(94,89,241,1)",
  "rgba(94,227,66,1)",
  "rgba(24,104,160,1)",
  "rgba(143,49,133,1)",
  "rgba(143,153,120,1)",
  "rgba(157,202,215,1)",
  "rgba(83,224,189,1)",
  "rgba(146,73,146,1)",
  "rgba(106,87,14,1)",
  "rgba(128,175,204,1)",
  "rgba(255,152,192,1)",
  "rgba(114,124,26,1)",
  "rgba(29,87,45,1)",
  "rgba(85,187,176,1)",
  "rgba(147,246,71,1)",
  "rgba(225,245,46,1)",
  "rgba(154,74,28,1)",
  "rgba(46,210,166,1)",
  "rgba(0,21,146,1)",
  "rgba(151,234,204,1)",
  "rgba(6,176,251,1)",
  "rgba(117,145,68,1)",
  "rgba(52,213,110,1)",
  "rgba(0,238,98,1)",
  "rgba(131,247,166,1)",
  "rgba(96,163,138,1)",
  "rgba(101,145,175,1)",
  "rgba(210,151,152,1)",
  "rgba(15,129,109,1)",
  "rgba(141,194,139,1)",
  "rgba(207,18,84,1)",
  "rgba(9,73,106,1)",
  "rgba(45,253,31,1)",
  "rgba(16,35,96,1)",
  "rgba(85,89,104,1)",
  "rgba(209,128,156,1)",
  "rgba(239,233,55,1)",
  "rgba(243,169,102,1)",
  "rgba(62,189,87,1)",
  "rgba(85,152,82,1)",
  "rgba(156,224,155,1)",
  "rgba(82,178,230,1)",
  "rgba(178,194,82,1)",
  "rgba(13,228,178,1)",
  "rgba(43,126,243,1)",
  "rgba(50,98,153,1)",
  "rgba(29,13,162,1)",
  "rgba(148,232,168,1)",
  "rgba(171,119,210,1)",
  "rgba(157,1,75,1)",
  "rgba(7,86,213,1)",
  "rgba(100,38,84,1)",
  "rgba(227,108,25,1)",
  "rgba(198,147,163,1)",
  "rgba(134,100,61,1)",
  "rgba(248,229,179,1)",
  "rgba(202,128,34,1)",
  "rgba(34,104,20,1)",
  "rgba(147,177,197,1)",
  "rgba(112,251,128,1)",
  "rgba(137,217,148,1)",
  "rgba(35,24,240,1)",
  "rgba(105,35,19,1)",
  "rgba(255,195,153,1)",
  "rgba(11,110,17,1)",
  "rgba(28,79,231,1)",
  "rgba(43,45,43,1)",
  "rgba(129,55,237,1)",
  "rgba(73,67,147,1)",
  "rgba(85,95,173,1)",
  "rgba(107,113,220,1)",
  "rgba(253,76,200,1)",
  "rgba(205,34,163,1)",
  "rgba(183,8,116,1)",
  "rgba(84,8,93,1)",
  "rgba(102,237,67,1)",
  "rgba(171,242,188,1)",
  "rgba(252,61,17,1)",
  "rgba(182,113,84,1)",
  "rgba(159,152,14,1)",
  "rgba(102,200,243,1)",
  "rgba(130,202,173,1)",
  "rgba(154,64,11,1)",
  "rgba(228,130,176,1)",
  "rgba(4,26,148,1)",
  "rgba(231,235,20,1)",
  "rgba(212,230,222,1)",
  "rgba(119,10,6,1)",
  "rgba(66,178,251,1)",
  "rgba(166,238,211,1)",
  "rgba(36,93,32,1)",
  "rgba(157,78,200,1)",
  "rgba(104,20,54,1)",
  "rgba(254,237,137,1)",
  "rgba(45,166,175,1)",
  "rgba(119,138,170,1)",
  "rgba(192,83,163,1)",
  "rgba(157,148,196,1)",
  "rgba(18,119,131,1)",
  "rgba(255,51,45,1)",
  "rgba(179,7,17,1)",
  "rgba(196,204,201,1)",
  "rgba(71,213,79,1)",
  "rgba(49,1,132,1)", //added 349 new colors on 8/22/23
  "rgba(146,115,24,1)",
  "rgba(83,140,165,1)",
  "rgba(72,60,60,1)",
  "rgba(210,251,148,1)",
  "rgba(91,238,239,1)",
  "rgba(139,228,225,1)",
  "rgba(113,220,49,1)",
  "rgba(98,168,116,1)",
  "rgba(22,22,206,1)",
  "rgba(169,115,10,1)",
  "rgba(43,65,234,1)",
  "rgba(218,57,188,1)",
  "rgba(223,210,43,1)",
  "rgba(87,66,49,1)",
  "rgba(68,137,239,1)",
  "rgba(185,190,79,1)",
  "rgba(13,54,90,1)",
  "rgba(174,231,235,1)",
  "rgba(167,156,239,1)",
  "rgba(29,88,170,1)",
  "rgba(91,104,220,1)",
  "rgba(50,37,199,1)",
  "rgba(226,245,69,1)",
  "rgba(10,151,202,1)",
  "rgba(37,62,0,1)",
  "rgba(134,205,66,1)",
  "rgba(18,10,76,1)",
  "rgba(19,58,198,1)",
  "rgba(133,174,42,1)",
  "rgba(57,20,122,1)",
  "rgba(106,78,74,1)",
  "rgba(184,44,235,1)",
  "rgba(186,71,187,1)",
  "rgba(116,216,244,1)",
  "rgba(21,94,138,1)",
  "rgba(157,82,184,1)",
  "rgba(208,61,88,1)",
  "rgba(254,111,86,1)",
  "rgba(124,99,47,1)",
  "rgba(232,240,38,1)",
  "rgba(41,178,245,1)",
  "rgba(220,76,219,1)",
  "rgba(36,1,69,1)",
  "rgba(188,76,226,1)",
  "rgba(182,191,81,1)",
  "rgba(6,167,116,1)",
  "rgba(88,79,170,1)",
  "rgba(141,15,91,1)",
  "rgba(105,96,26,1)",
  "rgba(219,83,217,1)",
  "rgba(121,164,251,1)",
  "rgba(82,223,250,1)",
  "rgba(239,24,5,1)",
  "rgba(182,115,240,1)",
  "rgba(145,151,114,1)",
  "rgba(15,65,184,1)",
  "rgba(144,83,146,1)",
  "rgba(214,209,236,1)",
  "rgba(224,227,156,1)",
  "rgba(107,142,1,1)",
  "rgba(211,57,228,1)",
  "rgba(252,4,70,1)",
  "rgba(218,235,215,1)",
  "rgba(25,15,157,1)",
  "rgba(176,247,98,1)",
  "rgba(29,136,87,1)",
  "rgba(187,21,204,1)",
  "rgba(177,194,26,1)",
  "rgba(91,62,31,1)",
  "rgba(1,224,135,1)",
  "rgba(67,12,25,1)",
  "rgba(118,45,22,1)",
  "rgba(81,72,224,1)",
  "rgba(95,134,17,1)",
  "rgba(27,201,119,1)",
  "rgba(132,209,60,1)",
  "rgba(242,121,3,1)",
  "rgba(236,207,57,1)",
  "rgba(224,109,147,1)",
  "rgba(100,205,221,1)",
  "rgba(31,81,131,1)",
  "rgba(173,83,74,1)",
  "rgba(74,32,115,1)",
  "rgba(70,95,122,1)",
  "rgba(90,14,17,1)",
  "rgba(93,155,78,1)",
  "rgba(80,163,118,1)",
  "rgba(75,189,52,1)",
  "rgba(55,100,24,1)",
  "rgba(116,158,11,1)",
  "rgba(177,175,106,1)",
  "rgba(63,228,141,1)",
  "rgba(1,44,136,1)",
  "rgba(188,139,246,1)",
  "rgba(52,171,64,1)",
  "rgba(111,1,188,1)",
  "rgba(109,204,0,1)",
  "rgba(167,14,159,1)",
  "rgba(124,42,169,1)",
  "rgba(238,68,245,1)",
  "rgba(110,58,36,1)",
  "rgba(158,66,27,1)",
  "rgba(236,128,223,1)",
  "rgba(153,139,168,1)",
  "rgba(133,8,28,1)",
  "rgba(191,214,227,1)",
  "rgba(158,159,126,1)",
  "rgba(102,86,194,1)",
  "rgba(185,255,149,1)",
  "rgba(185,30,206,1)",
  "rgba(178,53,56,1)",
  "rgba(152,135,30,1)",
  "rgba(23,140,35,1)",
  "rgba(122,53,85,1)",
  "rgba(66,74,164,1)",
  "rgba(227,23,40,1)",
  "rgba(192,58,167,1)",
  "rgba(255,164,171,1)",
  "rgba(28,108,181,1)",
  "rgba(68,201,19,1)",
  "rgba(61,6,185,1)",
  "rgba(77,192,193,1)",
  "rgba(255,101,9,1)",
  "rgba(162,32,111,1)",
  "rgba(157,76,213,1)",
  "rgba(240,143,127,1)",
  "rgba(89,250,205,1)",
  "rgba(224,74,86,1)",
  "rgba(156,45,10,1)",
  "rgba(244,167,66,1)",
  "rgba(73,208,69,1)",
  "rgba(22,2,253,1)",
  "rgba(89,142,184,1)",
  "rgba(39,3,24,1)",
  "rgba(183,154,180,1)",
  "rgba(103,100,74,1)",
  "rgba(218,152,195,1)",
  "rgba(79,175,7,1)",
  "rgba(16,99,167,1)",
  "rgba(200,205,30,1)",
  "rgba(101,45,12,1)",
  "rgba(238,189,187,1)",
  "rgba(213,32,168,1)",
  "rgba(118,187,54,1)",
  "rgba(168,33,115,1)",
  "rgba(223,56,96,1)",
  "rgba(25,46,112,1)",
  "rgba(10,63,135,1)",
  "rgba(80,230,146,1)",
  "rgba(177,114,45,1)",
  "rgba(94,9,27,1)",
  "rgba(75,16,133,1)",
  "rgba(19,215,8,1)",
  "rgba(8,166,213,1)",
  "rgba(98,187,238,1)",
  "rgba(54,133,113,1)",
  "rgba(161,176,30,1)",
  "rgba(244,143,57,1)",
  "rgba(198,218,64,1)",
  "rgba(99,33,55,1)",
  "rgba(95,148,75,1)",
  "rgba(150,84,50,1)",
  "rgba(255,149,171,1)",
  "rgba(75,84,152,1)",
  "rgba(180,86,34,1)",
  "rgba(38,122,105,1)",
  "rgba(182,75,232,1)",
  "rgba(26,66,42,1)",
  "rgba(49,55,78,1)",
  "rgba(206,158,218,1)",
  "rgba(159,94,53,1)",
  "rgba(44,146,163,1)",
  "rgba(63,102,6,1)",
  "rgba(63,193,123,1)",
  "rgba(247,164,114,1)",
  "rgba(33,175,188,1)",
  "rgba(12,179,25,1)",
  "rgba(117,243,63,1)",
  "rgba(144,158,196,1)",
  "rgba(44,119,152,1)",
  "rgba(219,44,105,1)",
  "rgba(6,82,32,1)",
  "rgba(135,23,16,1)",
  "rgba(253,126,9,1)",
  "rgba(231,249,64,1)",
  "rgba(205,4,44,1)",
  "rgba(247,86,189,1)",
  "rgba(181,248,219,1)",
  "rgba(210,245,217,1)",
  "rgba(220,116,108,1)",
  "rgba(42,53,255,1)",
  "rgba(33,159,17,1)",
  "rgba(242,227,63,1)",
  "rgba(112,91,123,1)",
  "rgba(194,172,194,1)",
  "rgba(119,65,36,1)",
  "rgba(235,50,208,1)",
  "rgba(77,177,47,1)",
  "rgba(94,66,22,1)",
  "rgba(201,229,139,1)",
  "rgba(42,39,130,1)",
  "rgba(240,148,101,1)",
  "rgba(79,50,52,1)",
  "rgba(160,19,5,1)",
  "rgba(85,221,82,1)",
  "rgba(89,48,12,1)",
  "rgba(98,23,79,1)",
  "rgba(36,237,203,1)",
  "rgba(195,71,118,1)",
  "rgba(91,133,127,1)",
  "rgba(253,106,213,1)",
  "rgba(74,56,233,1)",
  "rgba(16,166,9,1)",
  "rgba(96,71,111,1)",
  "rgba(254,194,194,1)",
  "rgba(16,209,76,1)",
  "rgba(229,173,78,1)",
  "rgba(219,5,76,1)",
  "rgba(250,72,102,1)",
  "rgba(99,116,133,1)",
  "rgba(84,242,129,1)",
  "rgba(40,21,170,1)",
  "rgba(66,106,254,1)",
  "rgba(248,210,235,1)",
  "rgba(89,139,149,1)",
  "rgba(157,131,58,1)",
  "rgba(48,115,2,1)",
  "rgba(9,24,120,1)",
  "rgba(213,113,144,1)",
  "rgba(217,168,225,1)",
  "rgba(246,114,167,1)",
  "rgba(194,77,183,1)",
  "rgba(228,140,176,1)",
  "rgba(147,124,115,1)",
  "rgba(209,55,112,1)",
  "rgba(169,130,126,1)",
  "rgba(97,66,57,1)",
  "rgba(25,50,255,1)",
  "rgba(3,189,42,1)",
  "rgba(20,157,0,1)",
  "rgba(75,89,117,1)",
  "rgba(248,129,197,1)",
  "rgba(75,228,110,1)",
  "rgba(240,147,11,1)",
  "rgba(94,181,217,1)",
  "rgba(57,20,0,1)",
  "rgba(42,171,110,1)",
  "rgba(44,194,209,1)",
  "rgba(22,156,165,1)",
  "rgba(14,134,75,1)",
  "rgba(54,158,109,1)",
  "rgba(36,45,122,1)",
  "rgba(81,194,177,1)",
  "rgba(249,24,91,1)",
  "rgba(30,207,93,1)",
  "rgba(230,80,208,1)",
  "rgba(50,98,70,1)",
  "rgba(172,212,43,1)",
  "rgba(120,184,91,1)",
  "rgba(191,152,25,1)",
  "rgba(86,32,174,1)",
  "rgba(43,252,84,1)",
  "rgba(101,239,209,1)",
  "rgba(213,27,49,1)",
  "rgba(115,27,72,1)",
  "rgba(85,172,136,1)",
  "rgba(97,219,251,1)",
  "rgba(187,141,38,1)",
  "rgba(7,178,100,1)",
  "rgba(181,171,123,1)",
  "rgba(10,91,72,1)",
  "rgba(163,66,66,1)",
  "rgba(35,38,176,1)",
  "rgba(126,140,29,1)",
  "rgba(191,168,73,1)",
  "rgba(215,83,200,1)",
  "rgba(149,150,114,1)",
  "rgba(184,47,160,1)",
  "rgba(228,181,181,1)",
  "rgba(160,202,170,1)",
  "rgba(117,164,97,1)",
  "rgba(36,5,116,1)",
  "rgba(47,39,237,1)",
  "rgba(175,25,236,1)",
  "rgba(56,117,17,1)",
  "rgba(68,13,179,1)",
  "rgba(171,181,127,1)",
  "rgba(252,177,122,1)",
  "rgba(207,241,117,1)",
  "rgba(82,170,14,1)",
  "rgba(56,113,42,1)",
  "rgba(17,4,235,1)",
  "rgba(6,160,152,1)",
  "rgba(51,114,64,1)",
  "rgba(141,186,170,1)",
  "rgba(231,6,24,1)",
  "rgba(169,76,222,1)",
  "rgba(204,180,221,1)",
  "rgba(232,64,245,1)",
  "rgba(124,182,88,1)",
  "rgba(199,177,134,1)",
  "rgba(15,35,33,1)",
  "rgba(54,105,202,1)",
  "rgba(227,39,190,1)",
  "rgba(9,12,169,1)",
  "rgba(26,75,103,1)",
  "rgba(73,59,61,1)",
  "rgba(58,238,223,1)",
  "rgba(56,244,206,1)",
  "rgba(35,248,81,1)",
  "rgba(112,107,147,1)",
  "rgba(32,49,207,1)",
  "rgba(163,61,16,1)",
  "rgba(143,79,102,1)",
  "rgba(112,173,48,1)",
  "rgba(28,207,88,1)",
  "rgba(91,186,58,1)",
  "rgba(122,41,190,1)",
  "rgba(102,122,35,1)",
  "rgba(205,41,14,1)",
  "rgba(9,13,211,1)",
  "rgba(5,234,148,1)",
  "rgba(134,108,173,1)",
  "rgba(211,3,22,1)",
  "rgba(216,230,25,1)",
  "rgba(36,42,102,1)",
  "rgba(12,157,85,1)",
  "rgba(100,45,84,1)",
  "rgba(60,78,29,1)",
  "rgba(19,92,122,1)",
  "rgba(28,202,119,1)",
  "rgba(219,168,138,1)",
  "rgba(205,152,219,1)",
  "rgba(247,110,192,1)",
  "rgba(15,26,13,1)",
  "rgba(230,100,180,1)",
  "rgba(100,99,236,1)",
  "rgba(43,134,153,1)",
  "rgba(53,161,59,1)",
  "rgba(106,123,63,1)",
  "rgba(29,161,53,1)",
  "rgba(158,254,7,1)",
  "rgba(41,227,115,1)",
  "rgba(34,188,199,1)",
  "rgba(226,247,114,1)",
  "rgba(70,203,74,1)",
  "rgba(122,166,243,1)",
  "rgba(180,46,133,1)",
  "rgba(53,224,44,1)",
  "rgba(2,27,37)", //ADDED 200 NEW COLORS 8/20/24
  "rgba(219,84,240)",
  "rgba(192,86,241)",
  "rgba(129,244,219)",
  "rgba(75,226,249)",
  "rgba(69,174,227)",
  "rgba(44,74,163)",
  "rgba(35,208,243)",
  "rgba(233,159,25)",
  "rgba(204,110,201)",
  "rgba(105,20,151)",
  "rgba(177,239,101)",
  "rgba(206,101,133)",
  "rgba(93,97,85)",
  "rgba(204,141,233)",
  "rgba(115,80,210)",
  "rgba(109,84,35)",
  "rgba(240,54,5)",
  "rgba(100,103,87)",
  "rgba(168,252,53)",
  "rgba(149,206,162)",
  "rgba(16,155,84)",
  "rgba(43,34,83)",
  "rgba(117,43,169)",
  "rgba(238,46,149)",
  "rgba(129,1,55)",
  "rgba(148,190,248)",
  "rgba(213,81,78)",
  "rgba(28,140,204)",
  "rgba(76,104,90)",
  "rgba(171,215,240)",
  "rgba(58,74,63)",
  "rgba(0,85,126)",
  "rgba(16,247,124)",
  "rgba(123,215,236)",
  "rgba(195,40,111)",
  "rgba(87,241,141)",
  "rgba(169,195,219)",
  "rgba(113,181,36)",
  "rgba(37,60,11)",
  "rgba(55,194,34)",
  "rgba(55,129,205)",
  "rgba(35,73,240)",
  "rgba(81,216,199)",
  "rgba(203,20,46)",
  "rgba(185,19,6)",
  "rgba(162,38,248)",
  "rgba(75,110,230)",
  "rgba(245,65,190)",
  "rgba(238,52,201)",
  "rgba(115,168,211)",
  "rgba(31,162,101)",
  "rgba(47,195,47)",
  "rgba(208,221,108)",
  "rgba(3,13,11)",
  "rgba(134,90,243)",
  "rgba(187,163,85)",
  "rgba(215,86,28)",
  "rgba(67,145,57)",
  "rgba(42,233,66)",
  "rgba(221,80,212)",
  "rgba(49,171,206)",
  "rgba(231,44,102)",
  "rgba(138,129,186)",
  "rgba(37,31,151)",
  "rgba(115,3,121)",
  "rgba(194,82,76)",
  "rgba(19,250,127)",
  "rgba(135,56,40)",
  "rgba(169,131,17)",
  "rgba(246,27,195)",
  "rgba(110,133,188)",
  "rgba(217,12,235)",
  "rgba(176,229,251)",
  "rgba(46,194,162)",
  "rgba(96,163,68)",
  "rgba(162,199,35)",
  "rgba(195,197,9)",
  "rgba(76,222,199)",
  "rgba(243,241,167)",
  "rgba(253,18,165)",
  "rgba(96,231,47)",
  "rgba(203,56,143)",
  "rgba(36,209,12)",
  "rgba(131,204,137)",
  "rgba(241,77,18)",
  "rgba(110,31,198)",
  "rgba(5,176,166)",
  "rgba(112,167,11)",
  "rgba(199,144,191)",
  "rgba(3,6,84)",
  "rgba(115,103,133)",
  "rgba(123,65,131)",
  "rgba(159,169,229)",
  "rgba(20,148,90)",
  "rgba(185,109,6)",
  "rgba(204,35,119)",
  "rgba(246,195,254)",
  "rgba(13,93,21)",
  "rgba(113,177,40)",
  "rgba(14,251,253)",
  "rgba(22,13,197)",
  "rgba(203,122,46)",
  "rgba(235,32,155)",
  "rgba(173,2,171)",
  "rgba(207,176,120)",
  "rgba(217,215,230)",
  "rgba(196,251,21)",
  "rgba(24,146,48)",
  "rgba(142,48,46)",
  "rgba(232,52,11)",
  "rgba(232,186,120)",
  "rgba(138,41,91)",
  "rgba(226,7,104)",
  "rgba(226,143,181)",
  "rgba(236,140,120)",
  "rgba(87,115,247)",
  "rgba(187,177,171)",
  "rgba(91,171,194)",
  "rgba(25,100,75)",
  "rgba(3,191,7)",
  "rgba(169,160,200)",
  "rgba(49,114,244)",
  "rgba(182,182,130)",
  "rgba(254,206,17)",
  "rgba(55,108,174)",
  "rgba(200,89,233)",
  "rgba(220,249,58)",
  "rgba(62,45,211)",
  "rgba(207,85,88)",
  "rgba(19,120,230)",
  "rgba(215,17,239)",
  "rgba(118,233,196)",
  "rgba(184,77,239)",
  "rgba(71,255,76)",
  "rgba(240,32,90)",
  "rgba(232,153,226)",
  "rgba(138,243,242)",
  "rgba(114,20,74)",
  "rgba(38,160,124)",
  "rgba(237,191,126)",
  "rgba(76,44,166)",
  "rgba(62,70,186)",
  "rgba(133,77,76)",
  "rgba(10,85,50)",
  "rgba(126,220,143)",
  "rgba(73,158,17)",
  "rgba(185,181,250)",
  "rgba(217,30,175)",
  "rgba(7,195,130)",
  "rgba(219,234,16)",
  "rgba(231,54,162)",
  "rgba(60,155,138)",
  "rgba(67,54,11)",
  "rgba(142,32,238)",
  "rgba(133,11,148)",
  "rgba(254,122,105)",
  "rgba(201,217,247)",
  "rgba(13,73,25)",
  "rgba(198,153,237)",
  "rgba(60,43,2)",
  "rgba(156,241,182)",
  "rgba(78,131,184)",
  "rgba(153,204,99)",
  "rgba(243,138,65)",
  "rgba(68,203,64)",
  "rgba(184,113,125)",
  "rgba(13,121,143)",
  "rgba(133,164,228)",
  "rgba(151,254,65)",
  "rgba(2,126,220)",
  "rgba(187,241,108)",
  "rgba(216,249,157)",
  "rgba(120,168,94)",
  "rgba(251,102,135)",
  "rgba(212,219,20)",
  "rgba(202,106,73)",
  "rgba(78,18,14)",
  "rgba(77,135,185)",
  "rgba(111,126,146)",
  "rgba(168,237,171)",
  "rgba(57,91,102)",
  "rgba(40,77,189)",
  "rgba(217,33,154)",
  "rgba(103,172,163)",
  "rgba(16,111,31)",
  "rgba(41,47,91)",
  "rgba(190,96,15)",
  "rgba(17,85,202)",
  "rgba(79,142,216)",
  "rgba(173,224,83)",
  "rgba(71,246,206)",
  "rgba(124,208,4)",
  "rgba(33,47,92)",
  "rgba(192,193,81)",
  "rgba(57,114,140)",
  "rgba(162,13,222)",
  "rgba(54,65,218)",
  "rgba(57,112,68)",
  "rgba(29,96,95)",
];
