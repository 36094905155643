import { Typography } from "@mui/material";
import React from "react";

export default function Logo() {
  return (
    <Typography variant="h1" className="logoTypog">
      RGBdle
    </Typography>
  );
}
